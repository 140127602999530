<template>
  <div class="time-curve-wrapper">
    <div class="card wrapper" :class="timeCurveTopDate ? 'zoomed' : ''">
      <div class="card-header">
        <div class="header-title">Courbe temporelle des publications</div>
        <div class="header-tools">
          <DownloadButton @click="downloadAsExcel" />
          <RadioButtons
            :options="intervalsOptions"
            :active-option="timeCurveInterval"
            @select-option="changeInterval"
          />
        </div>
      </div>
      <div v-if="isTimeCurveLoading" class="loading-wrapper">
        <HollowDotsSpinner :animation-duration="1000" color="var(--clr-p6)" />
      </div>
      <NoData v-if="!isTimeCurveLoading && !timeCurve" />
      <GenericTimeCurve
        v-else-if="!isTimeCurveLoading && timeCurve"
        :data="timeCurve"
        :platforms="indexesFilters"
        :interval="timeCurveInterval"
        @update-top-date="updateTopDate"
      />
    </div>
    <div v-if="timeCurveTopDate" class="zoom-wrapper">
      <GenericTimeCurveZoom
        :top-date="timeCurveTopDate"
        :top-articles="timeCurveTopArticles"
        :top-articles-filters="timeCurveTopArticlesFilters"
        :top-publications="timeCurveTopPublications"
        :top-publications-count="timeCurveTopPublicationsCount"
        :top-articles-count="timeCurveTopArticlesCount"
        :is-publications-loading="store.isTimeCurvePublicationsLoading"
        @update-top-articles-filters="updateTopArticlesFilters"
        @update-publications-filters="updatePublicationsFilters"
        @closes-zoom="closeZoom"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import xlsx from "json-as-xlsx";
import { HollowDotsSpinner } from "epic-spinners";
import DownloadButton from "@/components/reusable/atoms/DownloadButton.vue";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import GenericTimeCurve from "@/components/reusable/organisms/GenericTimeCurve.vue";
import { TIME_INTERVALS } from "@/constants/time-interval";
import { getFormattedDate } from "@/utils/date.utils";
import { useSocialMediaStatsStore } from "@/stores/social-media/statistics";
import GenericTimeCurveZoom from "@/components/reusable/organisms/GenericTimeCurveZoom.vue";

const store = useSocialMediaStatsStore();
const {
  indexesFilters,
  isTimeCurveLoading,
  timeCurve,
  timeCurveInterval,
  timeCurveTopDate,
  timeCurveTopArticles,
  timeCurveTopArticlesFilters,
  timeCurveTopArticlesCount,
  timeCurveTopPublications,
  timeCurveTopPublicationsCount,
} = storeToRefs(store);

const intervalsOptions = [
  {
    label: "Mois",
    value: TIME_INTERVALS.MONTH,
  },
  {
    label: "Jour",
    value: TIME_INTERVALS.DAY,
  },
  {
    label: "Heure",
    value: TIME_INTERVALS.HOUR,
  },
];

const changeInterval = (e) => {
  timeCurveInterval.value = e;
  timeCurveTopDate.value = null;
  store.getTimeCurve();
};

function closeZoom() {
  timeCurveTopDate.value = null;
}

const updateTopDate = (topDate) => {
  timeCurveTopDate.value = topDate;
};

function downloadAsExcel() {
  const intervalName = intervalsOptions.find(
    (elem) => elem.value === timeCurveInterval.value
  ).label;

  const dataAsJson = [
    {
      sheet: "Export publications",
      columns: [
        { label: intervalName, value: "time" },
        { label: "Nombre de publications", value: "nbPublications" },
      ],
      content: Object.entries(timeCurve.value).map(([key, val]) => ({
        time: getFormattedDate(key, timeCurveInterval.value),
        nbPublications: val,
      })),
    },
  ];

  xlsx(dataAsJson, {
    fileName: "Export publications",
  });
}

const updateTopArticlesFilters = (articlesFilters) => {
  store.timeCurveTopArticlesFilters = articlesFilters;
};

const updatePublicationsFilters = (publicationsFilters) => {
  store.timeCurveTopPublicationsFilters = publicationsFilters;
};
</script>

<style lang="scss" scoped>
.time-curve-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.wrapper {
  display: flex;
  flex-direction: column;
  grid-column: span 2;

  .card-header {
    display: flex;
    justify-content: space-between;

    .header-title {
      font-size: 1.063em;
      font-weight: 600;
      color: black;
    }

    .header-tools {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &.zoomed {
    grid-column: span 1;
  }
}

.zoom-wrapper {
  grid-column: span 1;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
</style>
