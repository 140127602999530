<template>
  <AbstractCard
    color="var(--clr-service-report)"
    icon="fa-kit fa-cr"
    follawed-name="follawed_compte_rendus"
    :doc-id="document._id"
    :target-url="routingUrl"
  >
    <template #header>
      {{ getDayTimeFormat(document.created_at) }}
    </template>

    <template #content>
      <ReportCardContent :report="document" is-homepage />
    </template>
  </AbstractCard>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import AbstractCard from "../abstract/AbstractCard.vue";
import ReportCardContent from "@/components/legi-reports/molecules/ReportCardContent.vue";
import { getDayTimeFormat } from "@/utils/formatDate";

const props = defineProps({
  document: {
    type: Object,
    required: true,
  },
});

const route = useRoute();

const routingUrl = computed(() => {
  let baseRoute = "";

  if (route.fullPath.includes("lobbies")) {
    baseRoute = "/lobbies";
    if (route.fullPath.includes("follawed")) {
      baseRoute += "/follawed";
    } else {
      baseRoute += `/${route.params._id}`;
    }
  }

  if (props.document.data_type === "legi_rapport") {
    baseRoute += "/reports";
  } else {
    baseRoute += "/comptes-rendus";
  }

  return `${baseRoute}/${encodeURIComponent(props.document.ref)}`;
});
</script>
