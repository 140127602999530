<template>
  <AbstractCard
    color="var(--clr-service-nominations)"
    icon="fa-sharp fa-solid fa-award"
    follawed-name="follawed_nominations"
    :doc-id="document._id"
    :target-url="{
      name: 'Nominations',
      query: {
        ...route.query,
        search: `_id:${document._id}`,
      },
    }"
  >
    <template #header>
      <span v-if="document.start_day">
        Prise de poste : {{ getDateShortFormat(document.start_day) }}
      </span>
      <span v-else>
        <!-- Can be removed once start_day is consistently sent by the gateway-->
        {{ getDateShortFormat(document.created_at) }}
      </span>
      <ThemeTag :theme="document.themes" />
    </template>

    <template #content>
      <NominationCardContent :nomination="document" />
    </template>
  </AbstractCard>
</template>

<script setup>
import { useRoute } from "vue-router";
import AbstractCard from "../abstract/AbstractCard.vue";
import { getDateShortFormat } from "@/utils/formatDate";
import ThemeTag from "@/components/reusable/atoms/ThemeTag.vue";
import NominationCardContent from "@/components/nominations/atoms/NominationCardContent.vue";

const route = useRoute();

defineProps({
  document: {
    type: Object,
    required: true,
  },
});
</script>
