<template>
  <div class="englobe-params">
    <div class="params-row">
      <!-- Back to index -->
      <RouterLink :to="backRoute" class="back">
        <i class="fa-solid fa-chevron-left" />
        Parties Prenantes
      </RouterLink>
    </div>
    <div class="params-row">
      <RouterRadioButtons :options="textOptions" />
    </div>

    <div
      v-if="isRoutePublications || isRouteMentions"
      class="stakeholders-zoom-params__filters"
    >
      <div class="params-row">
        <div class="params-row request-and-check-all">
          <SwitchSlide
            :is-active="!storeStakeholder.bypassQuery"
            @toggle="toggleUseRequest"
          >
            Utiliser la requête
          </SwitchSlide>

          <CheckBox
            :checked="isAllSelected"
            @change="
              () => (isAllSelected ? unselectAllFilters() : selectAllFilters())
            "
          >
            Toutes les publications
          </CheckBox>
        </div>

        <div class="params-row">
          <div class="page-settings">
            <SortBy
              :items="sortersNames"
              :selected-sort-item="currentSortLabel"
              @change-sort="handleSortChange"
            />

            <SelectItemsLimit
              :items-limit="currentFilters.size"
              @change-limit="changeLimit"
            >
              Publications
            </SelectItemsLimit>
          </div>
        </div>
      </div>

      <div v-if="isRoutePublications" class="params-row">
        <div class="page-settings filters">
          <FilterCheckBox
            v-for="itemSocialMedia in itemsFiltersSocialMedia"
            :key="itemSocialMedia.label"
            :label="itemSocialMedia.label"
            :color="itemSocialMedia.color"
            :is-checked="
              zoomPublicationsIndexes.includes(itemSocialMedia.indexes)
            "
            @check="addFilter(itemSocialMedia.indexes)"
            @uncheck="removeFilter(itemSocialMedia.indexes)"
          />
        </div>
      </div>
    </div>
    <div v-if="isRouteMentions"></div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import RouterRadioButtons from "@/components/reusable/molecules/RouterRadioButtons.vue";
import side_menu from "@/utils/side_menu";
import SwitchSlide from "@/components/reusable/atoms/SwitchSlide.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import FilterCheckBox from "@/components/reusable/atoms/FilterCheckBox.vue";
import { Platforms } from "@/utils/social360-platforms.js";
import { useStakeholdersStore } from "@/stores/social-media/stakeholders";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";

const storeStakeholder = useStakeholdersStore();
const { zoomPublicationsIndexes } = storeToRefs(storeStakeholder);

const currentRoute = useRoute();

const backRoute = "/stakeholders/ranking";

const routerOptions = side_menu
  .find((route) => route.name === "Social Media")
  .secondary_tabs.find((tab) => tab.name === "Parties Prenantes")
  .secondary_routes.find(
    (route) => route.path === "/stakeholders/:_id"
  ).children;

const textOptions = computed(() =>
  routerOptions.map((childRoute) => ({
    label: childRoute.name.replace("Zoom Partie Prenante - ", ""),
    value: `/stakeholders/${currentRoute.params._id}/${childRoute.path}`,
  }))
);

const sortersNames = {
  like: "Nombre de likes",
  impression: "Nombre d'impressions",
  created_at: "Date",
};

const isRoutePublications = computed(() => {
  return currentRoute.name === "Zoom Partie Prenante - Publications";
});

const isRouteMentions = computed(() => {
  return currentRoute.name === "Zoom Partie Prenante - Mentions";
});

const itemsFiltersSocialMedia = [
  {
    label: "𝕏",
    color: "var(--clr-twitter)",
    indexes: Platforms.TWITTER,
  },
  {
    label: "Linkedin",
    color: "var(--clr-linkedin)",
    indexes: Platforms.LINKEDIN,
  },
  {
    label: "Instagram",
    color: "var(--clr-instagram)",
    indexes: Platforms.INSTAGRAM,
  },
  {
    label: "Tiktok",
    color: "var(--clr-tiktok)",
    indexes: Platforms.TIKTOK,
  },
  {
    label: "Bluesky",
    color: "var(--clr-bluesky)",
    indexes: Platforms.BLUESKY,
  },
];

const currentFilters = computed(() => {
  if (isRoutePublications.value) {
    return storeStakeholder.zoomPublicationsFilters;
  } else if (isRouteMentions.value) {
    return storeStakeholder.zoomMentionsFilters;
  }
  return null;
});

const currentSortField = computed(
  () => currentFilters.value?.sort_field ?? sortersNames.number_of_publications
);

const currentSortLabel = computed(
  () => sortersNames[currentSortField.value] || "Sélectionner"
);

const resetPagination = () => {
  currentFilters.value.from = 0;
};

const getPublicationsList = async () => {
  resetPagination();
  if (isRouteMentions.value) {
    await storeStakeholder.getMentionsOfPublications(
      storeStakeholder.authorZoom.x_information?.username
    );
  } else {
    await storeStakeholder.getPublicationsList(currentRoute.params._id);
  }
};

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (currentSortField.value !== selectedSortField) {
      currentFilters.value.sort_field = selectedSortField;
      getPublicationsList();
    }
  }
};

const isAllSelected = computed(() => {
  return (
    zoomPublicationsIndexes.value.length === Object.keys(Platforms)?.length
  );
});

const addFilter = (filter) => {
  zoomPublicationsIndexes.value.push(filter);
  getPublicationsList();
};

const removeFilter = (filter) => {
  zoomPublicationsIndexes.value = zoomPublicationsIndexes.value.filter(
    (f) => f !== filter
  );
  getPublicationsList();
};

const unselectAllFilters = () => {
  zoomPublicationsIndexes.value = [];
  getPublicationsList();
};

const selectAllFilters = () => {
  zoomPublicationsIndexes.value = [
    Platforms.TWITTER,
    Platforms.LINKEDIN,
    Platforms.INSTAGRAM,
    Platforms.TIKTOK,
    Platforms.BLUESKY,
  ];
  getPublicationsList();
};

const changeLimit = (size) => {
  currentFilters.value.size = size;
  currentFilters.value.from = 0;
  getPublicationsList();
};

const toggleUseRequest = () => {
  storeStakeholder.bypassQuery = !storeStakeholder.bypassQuery;
  getPublicationsList();
};
</script>

<style scoped lang="scss">
.back {
  width: fit-content;
}

.filters {
  gap: 10px;
}

.filter {
  width: auto;
  gap: 40px;
}

.request-and-check-all {
  justify-content: flex-start;
  gap: 15px;
}

.stakeholders-zoom-params__filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
