<template>
  <section class="body">
    <div class="title">{{ props.jorf.title }}</div>

    <div class="issuer">{{ props.jorf.issuer }}</div>

    <div v-if="props.jorf.matched_words?.length > 0" class="content">
      <span class="matched-title">Mots matchés : </span>
      <span class="matched-words">{{ listOfWords }}</span>
    </div>
  </section>
  <section class="footer">
    <SourceLink
      :href="`https://www.legifrance.gouv.fr/jorf/id/${props.jorf.jorftext_id}`"
      >{{ props.jorf.jorf_title || "Voir JORF" }}</SourceLink
    >
  </section>
</template>

<script setup>
import { computed } from "vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";

const props = defineProps({
  jorf: {
    type: Object,
    required: true,
  },
});

const listOfWords = computed(() =>
  props.jorf.matched_words?.filter((_, idx) => idx < 10).join(", ")
);
</script>

<style lang="scss" scoped>
.matched-words {
  font-weight: 300;
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    display: flex;
    color: var(--clr-n8);
    font-weight: 600;
    font-size: 16px;
  }
}

.issuer {
  font-weight: 600;
  color: var(--clr-s7);
  margin-bottom: 5px;
}

.content {
  color: var(--clr-n8);

  .matched-title {
    font-weight: 500;
    color: var(--clr-s7);
  }
}

.footer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}
</style>
