<template>
  <div class="content">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <h5 v-if="isHomepage" class="title" v-html="article.title" />

    <div class="typo-body-regular">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="article.description" />
    </div>

    <div class="shares">
      <h3 class="last-shares-title title-small-semibold">
        Derniers partages&nbsp;:
      </h3>
      <div class="share-row">
        <div class="sharers">
          <PersonBadge
            v-for="person in article.authors"
            :key="person._id"
            :person="person"
          />
        </div>
        <div v-if="!clickableMetrics" class="metrics-wrapper">
          <div class="metric-data">
            <span class="big-number">{{
              SINumberFormatter(article.number_of_tweets)
            }}</span>
            tweets
          </div>
          <div class="metric-data">
            <span class="big-number">{{
              SINumberFormatter(article.number_of_retweets)
            }}</span>
            retweets
          </div>
        </div>
        <button
          v-else
          class="metrics-wrapper btn"
          :class="{ zoomed: isZoomed }"
          @click="
            isZoomed ? emit('closeZoom') : emit('activateZoom', article._id)
          "
          @mouseenter="isZoomBtnHovered = true"
          @mouseleave="isZoomBtnHovered = false"
        >
          <div v-if="isZoomed" class="metric-data">
            <span>Cacher les</span> tweets & retweets
          </div>
          <div v-else-if="isZoomBtnHovered" class="metric-data">
            <span>Voir les</span>tweets & retweets
          </div>
          <template v-else>
            <div class="metric-data">
              <span class="big-number">{{
                SINumberFormatter(article.number_of_tweets)
              }}</span>
              tweets
            </div>
            <div class="metric-data">
              <span class="big-number">{{
                SINumberFormatter(article.number_of_retweets)
              }}</span>
              retweets
            </div>
          </template>
        </button>
      </div>
    </div>

    <div v-if="article.published_at">
      <span class="date-name">Date de publication de l'article : </span>
      <span class="date-value">{{
        getDateShortFormat(article.published_at)
      }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import PersonBadge from "@/components/reusable/molecules/PersonBadge.vue";
import { SINumberFormatter } from "@/utils/helper.utils";
import { getDateShortFormat } from "@/utils/formatDate";

defineProps({
  article: {
    type: Object,
    required: true,
  },
  isZoomed: { type: Boolean, default: false },
  clickableMetrics: {
    type: Boolean,
    default: false,
  },
  isHomepage: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["activateZoom", "closeZoom"]);

const isZoomBtnHovered = ref(false);
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.shares {
  margin-top: auto;

  .share-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .sharers {
      display: flex;
      gap: 8px;
    }

    .metrics-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 170px;
      height: 60px;
      padding: 8px 16px;

      &.btn {
        box-shadow: var(--shd-card);
        border-radius: 4px;
        color: var(--clr-s7);
      }

      &.zoomed {
        background-color: var(--clr-s7);
        color: white;
      }

      .metric-data {
        display: flex;
        flex-direction: column;
        align-items: center;

        .big-number {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.date-name {
  color: var(--clr-s7);
}
.date-value {
  color: var(--clr-n6);
}
</style>
