<template>
  <div class="card">
    <section class="body">
      <section class="name-and-follaw">
        <div class="pic-and-name">
          <CardProfilePicture
            :picture-url="
              lobby.x_information?.profile_picture
                ? lobby.x_information.profile_picture
                : null
            "
            class="picture"
            :size="48"
          />
          <p class="name">{{ lobby.name }}</p>
        </div>
        <div class="follaw">
          <FollawButton
            v-if="lobby.national_id"
            :element-id="lobby.national_id"
            :feature="FOLLAWED_FEATURES.LOBBIES"
          />
        </div>
      </section>

      <section class="organisation-type">
        <p class="type capitalize-letter">{{ lobby.type }}</p>
      </section>

      <section class="lobby-infos">
        <div class="info city">
          <span class="icon"><i class="fa-solid fa-location-dot"></i></span>
          <p>{{ lobby.city }}</p>
        </div>
        <div class="info levels">
          <span class="icon"><i class="fa-solid fa-layer-group"></i></span>
          <p>{{ lobby.levels.join(", ") }}</p>
        </div>
        <div class="info categories">
          <span class="icon"><i class="fa-solid fa-briefcase"></i></span>
          <p>{{ lobby.categories.join(", ") }}</p>
        </div>
      </section>

      <section class="stats">
        <div class="latest-activity">
          <div class="latest-activity-stats">
            <div class="stat expenses">
              <p class="stat-name">Dépenses</p>
              <span class="number">
                {{ lobby.latest_activity.expenses ?? "/" }}
              </span>
            </div>

            <div class="stat money">
              <p class="stat-name">CA</p>
              <span class="number">
                {{ lobby.latest_activity.money ?? "/" }}
              </span>
            </div>

            <div class="stat employees">
              <p class="stat-name">
                Employé<template
                  v-if="lobby.latest_activity.number_of_employees > 1"
                  >s</template
                >
                <GenericTooltip top left nowrap>
                  <template #trigger>
                    <i class="fa-regular fa-circle-info"></i>
                  </template>
                  <template #text
                    >Exprimé en équivalent temps plein travaillé</template
                  >
                </GenericTooltip>
              </p>
              <span class="number">
                {{
                  SINumberFormatter(lobby.latest_activity.number_of_employees)
                }}
              </span>
            </div>
          </div>

          <p class="period">
            <span>Période concernée :</span>
            {{ dayjs(lobby.latest_activity.start).format("DD/MM/YYYY") }} au
            {{ dayjs(lobby.latest_activity.end).format("DD/MM/YYYY") }}
          </p>
        </div>

        <hr />

        <div class="twitter-stats">
          <div class="stat">
            <p class="stat-name">
              Publication<template v-if="lobby.number_of_publications > 1"
                >s</template
              >
            </p>
            <span class="number">{{
              SINumberFormatter(lobby.number_of_publications)
            }}</span>
          </div>
          <div class="stat followers">
            <p class="stat-name">
              Follower<template v-if="lobby.x_information?.followers_tot > 1"
                >s</template
              >
            </p>
            <span class="number">{{
              lobby.x_information?.followers_tot
                ? SINumberFormatter(lobby.x_information?.followers_tot)
                : "/"
            }}</span>
          </div>
        </div>
      </section>
      <section v-if="lobby.x_information?.description" class="twitter bio">
        <span class="title">Bio X :</span>
        <p>{{ lobby.x_information.description }}</p>
      </section>
      <section
        v-if="Object.values(lobby.top_thematics).length"
        class="top-tematics"
      >
        <span class="title">Top thématiques :</span>
        <LobbyTopThematics :thematics="lobby.top_thematics" />
      </section>
      <section
        v-if="Object.values(lobby.top_domains).length"
        class="top-domains"
      >
        <span class="title">Top domaines d'intervention :</span>
        <hr />
        <LobbyTopDomains :domains="lobby.top_domains" />
      </section>
      <section class="links">
        <div class="socials">
          <IconLink
            v-if="lobby.websites.twitter"
            :link="lobby.websites.twitter"
          >
            <i class="fa-brands fa-x-twitter"></i>
          </IconLink>
          <IconLink
            v-if="lobby.websites.linkedin"
            :link="lobby.websites.linkedin"
          >
            <i class="fa-brands fa-linkedin-in"></i>
          </IconLink>
          <IconLink
            v-if="lobby.websites.facebook"
            :link="lobby.websites.facebook"
          >
            <i class="fa-brands fa-facebook-f"></i>
          </IconLink>
          <IconLink
            v-if="lobby.websites.website"
            :link="lobby.websites.website"
          >
            <i class="fa-solid fa-globe-pointer"></i>
          </IconLink>
        </div>
        <SourceLink :href="sourceUrl">Source</SourceLink>
      </section>
    </section>
  </div>
</template>

<script setup>
import { computed } from "vue";
import dayjs from "dayjs";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import GenericTooltip from "@/components/reusable/atoms/GenericTooltip.vue";
import LobbyTopThematics from "./LobbyTopThematics.vue";
import LobbyTopDomains from "./LobbyTopDomains.vue";
import IconLink from "@/components/reusable/atoms/IconLink.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import { FOLLAWED_FEATURES } from "@/stores/user";

const props = defineProps({
  lobby: {
    type: Object,
    required: true,
  },
});

const sourceUrl = computed(
  () =>
    `https://www.hatvp.fr/fiche-organisation/?organisation=${props.lobby.national_id}`
);
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.card {
  color: var(--clr-n8);
  height: fit-content;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .name-and-follaw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .pic-and-name {
      display: flex;
      align-items: center;
      gap: 8px;

      .name {
        font-size: large;
        text-transform: capitalize;
      }
    }

    .follaw {
      margin-left: auto;
    }
  }

  .type {
    color: var(--clr-s7);
  }

  .lobby-infos {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .info {
      display: flex;
      gap: 8px;

      .icon {
        display: inline-flex;
        justify-content: center;
        width: 14px;
        padding: 3px 0;
        color: var(--clr-s6);
      }
    }

    p {
      line-height: 22px;
    }
  }

  .stats {
    display: flex;
    flex-direction: column;
    line-height: 24px;

    .latest-activity {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .latest-activity-stats,
    .twitter-stats {
      display: flex;
      justify-content: space-around;
      gap: 8px;

      &.twitter-stats {
        justify-content: center;
      }
    }

    hr {
      width: 70px;
      border: 0;
      border-top: 1px solid var(--clr-s6);
    }

    .stat {
      padding: 5px 0;
      text-align: center;

      .stat-name {
        color: var(--clr-s7);
        white-space: nowrap;
      }

      i {
        margin-left: 4px;
      }

      .number {
        white-space: nowrap;
      }
    }

    .period {
      width: 100%;
      text-align: center;
      font-size: 0.833rem;
      line-height: 18.66px;

      span {
        color: var(--clr-s7);
      }
    }
  }

  .title {
    font-weight: 600;
    text-wrap: nowrap;
  }

  .twitter {
    display: flex;
    gap: 5px;
  }

  .top-domains {
    .title {
      line-height: 1.5;
    }
    hr {
      margin: 4px 0;
      border: 0;
      border-top: 1px solid var(--clr-s6);
    }
  }

  .links {
    display: flex;
    justify-content: space-between;

    .socials {
      display: flex;
      gap: 8px;
    }
  }
}
</style>
