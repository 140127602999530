<template>
  <div class="card">
    <div class="author-card__header">
      <div class="author-card__tags">
        <GenericTag
          color="var(--clr-n8)"
          background-color="var(--clr-tag-stake)"
        >
          <span class="author-card--text-capitalize">
            {{ author.stakeholder }}
          </span>
        </GenericTag>
        <GroupTag v-if="author.group" :background-color="author.group.color">
          {{ author.group.acronym }}
        </GroupTag>
      </div>
    </div>

    <div class="author-card__separator" />

    <div class="author-card__body">
      <CardProfilePicture
        :picture-url="author.photo"
        class="author-card__picture"
        :size="70"
      />
      <div class="author-card__info">
        <div class="author-card__info__header">
          <div class="author-card__info__header-left">
            <span class="title-large-regular author-card--text-capitalize">
              {{ author.name }}
            </span>
            <PrmLink v-if="author.is_prm" :to="`/prm-national/${author._id}`" />
          </div>
          <FollawButton
            :element-id="author._id"
            :feature="FOLLAWED_FEATURES.TWEET_AUTHORS"
          />
        </div>
      </div>
    </div>

    <div class="author-card__social-handles">
      <p class="title-medium-semibold">Réseaux sociaux actifs :</p>
      <div class="author-card__handles">
        <template v-for="platform in authorPlatformsData" :key="platform.label">
          <span v-if="platform.url" class="author-card__handle">
            <i :class="platform.icon" :style="{ color: platform.color }" />
            <BaseLink :href="platform.url">@{{ platform.label }}</BaseLink>
          </span>
        </template>
      </div>
    </div>

    <p class="title-medium-semibold author-card__network-info">
      Informations par réseau :
    </p>

    <div v-if="authorInformationData.length === 1">
      <div>
        <div class="author-card__handle">
          <i
            :class="authorInformationData[0].icon"
            :style="{ color: authorInformationData[0].color }"
          />
          <BaseLink :href="authorInformationData[0].url"
            >@{{ authorInformationData[0].label }}</BaseLink
          >
        </div>
        <p class="typo-body-regular">
          {{ authorInformationData[0].description }}
        </p>
      </div>
    </div>

    <CarouselContent v-else :slides="authorInformationData">
      <template #default="{ slide }">
        <div class="author-card__slide">
          <div class="author-card__handle">
            <i :class="slide.icon" :style="{ color: slide.color }" />
            <BaseLink :href="slide.url">@{{ slide.label }}</BaseLink>
          </div>
          <p class="typo-body-regular">{{ slide.description }}</p>
        </div>
      </template>
    </CarouselContent>

    <div class="author-card__metrics">
      <div
        v-for="metric in metrics"
        :key="metric.label"
        class="author-card__metric"
      >
        <span class="author-card__metric-value">
          {{ SINumberFormatter(metric.value) }}
        </span>
        <span class="author-card__metric-label">
          {{ metric.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import PrmLink from "@/components/social-media/atoms/PrmLink.vue";
import CarouselContent from "@/components/reusable/atoms/CarouselContent.vue";

import { SINumberFormatter } from "@/utils/helper.utils.js";
import { FOLLAWED_FEATURES } from "@/stores/user";
import BaseLink from "@/components/general/atoms/BaseLink.vue";
import {
  getLinkedinHandle,
  useHandle,
} from "@/composables/social-media/useHandle";
import { Platforms } from "@/utils/social360-platforms";

const props = defineProps({
  author: {
    type: [Array, Object],
    required: true,
  },
});

const author = props.author;

const authorPlatformsData = [
  {
    name: Platforms.TWITTER,
    icon: "fa-brands fa-lg fa-square-x-twitter",
    color: "var(--clr-twitter)",
    handle: author.x_information?.username,
    description: author.x_information?.description,
  },
  {
    name: Platforms.LINKEDIN,
    icon: "fa-brands fa-lg fa-linkedin",
    color: "var(--clr-linkedin)",
    handle: getLinkedinHandle(author),
    description: author.linkedin_information?.description,
  },
  {
    name: Platforms.INSTAGRAM,
    icon: "fa-brands fa-lg fa-square-instagram",
    color: "var(--clr-instagram)",
    handle: author.instagram_information?.username,
    description: author.instagram_information?.description,
  },
  {
    name: Platforms.TIKTOK,
    icon: "fa-brands fa-tiktok",
    color: "var(--clr-tiktok-icon)",
    handle: author.tiktok_information?.username,
    description: author.tiktok_information?.description,
  },
  {
    name: Platforms.BLUESKY,
    icon: "fa-brands fa-lg fa-bluesky",
    color: "var(--clr-bluesky)",
    handle: author.bluesky_information?.username,
    description: author.bluesky_information?.description,
  },
].map((social) => {
  const handleData = useHandle(social.handle, social.name);
  return {
    ...handleData,
    name: social.name,
    icon: social.icon,
    color: social.color,
    description: social.description,
  };
});

const authorInformationData = authorPlatformsData
  .filter((social) => social.description)
  .map(({ icon, color, label, url, description }) => ({
    icon,
    color,
    label,
    url,
    description,
  }));

const metrics = [
  {
    label: "Abonnés",
    value: author.instagram_information?.followers_total || 0,
  },
  { label: "Impressions", value: author.number_of_impressions },
  { label: "Mentions", value: author.number_of_mentions },
  { label: "Publications", value: author.number_of_publications },
];
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.author-card {
  &--text-capitalize {
    text-transform: capitalize;
  }

  &__header,
  &__metrics {
    display: flex;
    justify-content: space-between;
  }

  &__social-handles,
  &__slide {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__body,
  &__tags,
  &__handles,
  &__handle {
    display: flex;
    gap: 4px;
  }

  &__handles {
    flex-wrap: wrap;
    gap: 8px;
  }

  &__handle {
    align-items: center;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__header {
      display: flex;
      justify-content: space-between;
      flex: 1;

      &-left {
        display: flex;
        justify-content: center;
        gap: 8px;
        width: 100%;
      }
    }
  }

  &__slide {
    width: 100%;
    padding: 0 48px;
  }

  &__network-info,
  &__separator {
    margin: 8px 0;
  }

  &__separator {
    border-top: 1.4px solid var(--clr-s6);
  }

  &__picture {
    min-width: 70px;
  }

  &__metric {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px 4px;
  }

  &__metric-value {
    font-size: 1.6875rem;
  }

  &__metric-label {
    color: var(--clr-s7);
  }
}
</style>
