<template>
  <div class="card petition-author">
    <!---------------------------------------- Header ---------------------------------------->
    <!--  Personnal informations -->
    <span class="personnal-infos-wrapper">
      <CardProfilePicture
        :picture-url="tweet.author?.photo"
        class="picture"
        :size="70"
      />
      <div v-if="tweet.author" class="author-infos">
        <!-- Name and twitter-id -->
        <span class="name">{{ tweet.author.name }}</span>
        <span class="handle">
          <a :href="authorTwitterUrl" target="_blank">@{{ authorHandle }}</a>
        </span>

        <PrmLink
          v-if="tweet.author.is_prm"
          :to="`/prm-national/${tweet.author._id}`"
        />
      </div>
    </span>

    <!---------------------------------------- Body ---------------------------------------->
    <div class="main">
      <div class="content typo-body-regular">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="tweetText"></div>
        <SourceLink
          v-for="url in tweetLinks"
          :key="url"
          :href="url"
          target="_blank"
        >
          {{ url }}
        </SourceLink>
      </div>

      <div class="timestamps-wrapper">
        <p>
          Tweet posté le :<span class="timestamp">{{
            getDayTimeFormat(tweet.created_at)
          }}</span
          ><SourceLink :href="sourceTweetUrl"></SourceLink>
        </p>
        <p v-if="tweet.last_update">
          Dèrnière Maj des métriques :<span class="timestamp">{{
            getDayTimeFormat(tweet.last_update)
          }}</span>
        </p>
      </div>
    </div>

    <!---------------------------------------- Footer ---------------------------------------->
    <div v-if="!tweet.is_retweet" class="metrics">
      <div class="retweets">
        <span class="number">{{
          SINumberFormatter(tweet.number_of_retweets)
        }}</span>
        <span class="retweets-label">Retweets</span>
      </div>
      <div class="comments-and-likes-wrapper">
        <CommentsAndLikes
          :comments="SINumberFormatter(tweet.comment)"
          :likes="SINumberFormatter(tweet.like)"
          :impressions="SINumberFormatter(tweet.impression)"
          class="comments-and-likes"
        />
      </div>
    </div>
    <div v-else class="is-retweet">
      <i class="fa-solid fa-retweet fa-xl"></i>
    </div>
  </div>
</template>

<script setup>
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import PrmLink from "@/components/social-media/atoms/PrmLink.vue";
import CommentsAndLikes from "@/components/social-media/atoms/CommentsAndLikes.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { SINumberFormatter } from "@/utils/helper.utils.js";
import { computed } from "vue";
import { getDayTimeFormat } from "@/utils/formatDate";

const props = defineProps({
  tweet: {
    type: Object,
    required: true,
  },
});

const authorHandle = computed(
  () => props.tweet.author.twitter_information?.handle_twitter
);
const authorTwitterUrl = computed(
  () => `https://twitter.com/${authorHandle.value}`
);
const sourceTweetUrl = computed(
  () =>
    `https://twitter.com/${authorHandle.value}/status/${props.tweet.id_twitter}`
);

const linksRegex = /(https:\/\/t.co\/[a-zA-Z0-9]{10})/g;

const tweetLinks = computed(() => props.tweet.text.match(linksRegex));
const tweetText = computed(() => props.tweet.text.replace(linksRegex, ""));
</script>

<style lang="scss" scoped>
.card {
  border-radius: 15px;
  box-shadow: none;
}
.comments-and-likes {
  display: inline-block;
}
// Overriding the default inline spacing from the source component for best suitability on this petition layout.
.comments-and-likes-wrapper :deep(.comments-and-likes span:first-child) {
  @media (max-width: 1400px) {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.personnal-infos-wrapper {
  display: flex;
  justify-content: flex-start;
}
.author-infos {
  margin: 10px 0 0 10px;
  display: flex;
  flex-direction: column;

  .name {
    font-weight: 600;
    overflow-wrap: anywhere;
    text-transform: capitalize;
    line-height: 22px;
  }
  .handle {
    margin-bottom: 4px;
  }
  a {
    margin-top: 5px;
  }
}
.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .content {
    margin: 10px 0;

    div {
      display: inline;
      line-height: 22px;
    }

    a {
      margin-left: 5px;
    }
  }

  .timestamps-wrapper {
    margin-top: auto;
    p {
      margin: 0;

      &:first-child {
        margin-bottom: 5px;
      }
    }

    .timestamp {
      margin: 0 5px;
    }
  }
}

.metrics {
  display: flex;
  justify-content: right;

  .retweets {
    flex-grow: 0.06;
    margin-top: 10px;
    display: flex;
  }
  .number {
    font-size: 1.25em;
    align-self: center;
  }
  .retweets-label {
    align-self: center;
  }
  .number,
  .retweets-label {
    margin-left: 0.24em;
  }
}

.is-retweet {
  display: flex;
  justify-content: right;
  margin: 15px 3px;
  text-align: center;
  color: var(--clr-service-twitter);
}
</style>
