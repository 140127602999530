<template>
  <div class="card latest-news">
    <div class="head">
      <h6 class="title">Nouveautés</h6>
      <div class="tools">
        <RadioButtons
          :active-option="activeValue"
          :options="options"
          @select-option="selectOption"
        />
      </div>
    </div>
    <div class="body">
      <div v-if="store.isLatestNewsLoading" class="loading-wrapper">
        <hollow-dots-spinner :animation-duration="1000" color="var(--clr-p4)" />
      </div>
      <template
        v-else-if="
          activeValue === 'lobbies' &&
          store.lobbyZoomLatestNews.lobbies.length > 0
        "
      >
        <LobbyActivityCard
          v-for="(item, index) in store.lobbyZoomLatestNews.lobbies"
          :key="index"
          :activity="item"
        />
      </template>
      <template
        v-else-if="
          activeValue === 'publications' &&
          store.lobbyZoomLatestNews.publications.length > 0
        "
      >
        <PublicationCard
          v-for="(item, index) in store.lobbyZoomLatestNews.publications"
          :key="index"
          :post="item"
        />
      </template>
      <template
        v-else-if="
          activeValue === 'articles' &&
          store.lobbyZoomLatestNews.articles.length > 0
        "
      >
        <XArticleCard
          v-for="(item, index) in store.lobbyZoomLatestNews.articles"
          :key="index"
          :article="item"
        />
      </template>
      <template
        v-else-if="
          activeValue === 'events' &&
          store.lobbyZoomLatestNews.events.length > 0
        "
      >
        <EventCard
          v-for="(item, index) in store.lobbyZoomLatestNews.events"
          :key="index"
          no-select
          :is-selected="false"
          :event="item"
        />
      </template>
      <template
        v-else-if="
          activeValue === 'compte_rendus' &&
          store.lobbyZoomLatestNews.compte_rendus.length > 0
        "
      >
        <ReportCard
          v-for="(item, index) in store.lobbyZoomLatestNews.compte_rendus"
          :key="index"
          :report="item"
        />
      </template>

      <EmptySection v-else />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { HollowDotsSpinner } from "epic-spinners";
import LobbyActivityCard from "@/components/lobbies/atoms/LobbyActivityCard.vue";
import XArticleCard from "@/components/social-media/molecules/XArticleCard.vue";
import EventCard from "@/components/agenda/organisms/EventCard.vue";
import ReportCard from "@/components/legi-reports/molecules/ReportCard.vue";
import EmptySection from "@/components/reusable/molecules/EmptySection.vue";
import { useLobbiesStore } from "@/stores/lobbies";
import RadioButtons from "@/components/reusable/atoms/RadioButtons.vue";
import PublicationCard from "@/components/social-media/posts/molecules/PublicationCard.vue";

const store = useLobbiesStore();
const route = useRoute();

const activeValue = ref("lobbies");

const options = ref([
  { label: "Lobbying", value: "lobbies" },
  { label: "Publications", value: "publications" },
  { label: "Articles", value: "articles" },
  { label: "Evènements", value: "events" },
  { label: "Comptes-rendus", value: "compte_rendus" },
]);

const selectOption = (newValue) => {
  activeValue.value = newValue;
};

store.readOneLobbyLatestNews(route.params._id);
</script>

<style lang="scss" scoped>
.latest-news {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 400px;
  padding: 8px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    .title {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
  }
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
