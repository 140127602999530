<template>
  <section class="body">
    <!-- Questions -->
    <div v-if="isHomepage" class="dates title-small-regular">
      <span>
        {{
          question.closed_at
            ? `Répondue le : ${dayjs(question.closed_at).format("DD/MM/YYYY")}`
            : "Absence de réponse"
        }}
      </span>
      <span> {{ question.legislature }}e législature</span>
    </div>

    <div class="card-title">
      <template v-if="question.title">
        <i v-if="!isHomepage" class="fa-kit fa-questions"></i>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span class="title-medium-semibold" v-html="question.title" />
      </template>
      <span v-else class="no-component-data">Donnée(s) manquante(s)</span>
    </div>

    <StatusTag :status="question.status" />

    <!-- Questions Author and Description -->
    <div class="author-infos-wrapper">
      <div class="question-author">
        <CardProfilePicture :picture-url="question.author.photo" :size="50" />
        <div class="name-and-group-wrapper">
          <span class="name">{{ question.author.name }}</span>
          <GroupTag
            v-if="question.author.group"
            :group-color="question.author.group.color"
          >
            {{ question.author.group.acronym }}
          </GroupTag>
        </div>
      </div>
      <div class="question-informations">
        <span>{{ capitalizeFirstLetter(question.type_question) }}</span>
        <span>n° : {{ question.number }}</span>
        <span
          ><i class="fas fa-map-marker-alt"></i
          ><span> {{ question.institution }}</span></span
        >
      </div>
    </div>
    <div class="question-details">
      <!-- Ministry-->
      <div>
        <span class="detail-title title-medium-regular"
          >Ministère attributaire :</span
        >{{ question.ministry_responsible }}
      </div>
      <!-- Matched words -->
      <div v-if="question.matched_words && question.matched_words.length > 0">
        <span class="detail-title title-medium-regular"
          >Mot{{ question.matched_words.length > 1 ? "s" : "" }} matché{{
            question.matched_words.length > 1 ? "s" : ""
          }}
          :</span
        >
        <!-- eslint-disable-next-line vue/no-v-html-->
        <span v-html="listOfWords"></span>
      </div>
      <!-- Answer limit -->
      <div>
        <span class="detail-title title-medium-regular"
          >Date limite pour répondre :</span
        >
        <!-- Set the created date + 2 Months as expected response -->
        <span class="response-limit-content">
          {{ dayjs(question.created_at).add(2, "month").format("DD/MM/YYYY") }}
        </span>
      </div>
    </div>
  </section>

  <section class="footer">
    <div class="themes-group">
      <ThemeTag
        v-for="(theme, index) in question.themes"
        :key="index"
        :theme="theme"
      />
    </div>

    <SourceLink :href="question.source">Source</SourceLink>
  </section>
</template>

<script setup>
import { computed } from "vue";
import dayjs from "dayjs";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import StatusTag from "@/components/reusable/atoms/StatusTag.vue";
import ThemeTag from "@/components/reusable/atoms/ThemeTag.vue";
import { capitalizeFirstLetter } from "@/utils/helper.utils";

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
  isHomepage: {
    type: Boolean,
    default: false,
  },
});

const listOfWords = computed(() =>
  props.question.matched_words?.filter((_, idx) => idx < 10).join(", ")
);
</script>

<style scoped lang="scss">
.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .dates {
    display: flex;
    justify-content: space-between;
  }

  .author-infos-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .question-author {
      display: flex;
      align-items: center;
      gap: 8px;

      .name-and-group-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .name {
          overflow-wrap: anywhere;
          text-transform: capitalize;
        }
      }
    }

    .question-informations {
      display: flex;
      flex-direction: column;
      gap: 4px;
      border-left: 1px solid var(--clr-s3);
      padding-left: 8px;
      color: var(--clr-s7);

      i {
        margin-right: 8px;
      }
    }
  }

  .matched-words {
    font-weight: 300;
  }

  .card-title {
    display: flex;
    align-items: center;
    gap: 8px;

    i {
      flex: none;
      border-radius: 50px;
      width: 32px;
      height: 32px;
      padding-top: 6.5px;
      font-size: 19px;
      color: white;
      background-color: var(--clr-service-question);
      text-align: center;
    }

    .no-component-data {
      font-style: italic;
    }
  }
}

.question-details {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .detail-title {
    margin-right: 4px;
    white-space: nowrap;
    color: var(--clr-s7);
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .themes-group {
    display: flex;
    gap: 4px;
  }
}
</style>
