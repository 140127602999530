<template>
  <div class="englobe-params wrapper-filters">
    <div class="params-row top">
      <FiltersMenu
        :active-filters="activeFilters"
        :is-loading="!store.filters"
        @remove-filter="removeFilterItem"
        @reset-all-filters="resetAllFilters"
      >
        <SearchAsYouTypeFilter
          filter="author"
          :items="store.authors"
          item-label="name"
          item-value="_id"
          :active-filters="selectedFilters.author"
          :is-loading="store.isAuthorFilterLoading"
          mode="purple"
          @search="fetchSearchAuthors"
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetAuthorFilter"
        >
          Auteurs
        </SearchAsYouTypeFilter>
        <SelectMultipleCounter
          v-for="(value, key) in filtersNames"
          :key="key"
          :filter="key"
          :items="store.filters[key]"
          :active-filters="selectedFilters[key]"
          mode="purple"
          has-search
          @add-filter-item="addFilterItem"
          @remove-filter-item="removeFilterItem"
          @reset-filter="resetFilter"
        >
          {{ value }}
        </SelectMultipleCounter>
      </FiltersMenu>
    </div>

    <div class="params-row">
      <template v-if="store.items">
        <TotalMatched :item-count="store.item_count" feminine />

        <div class="page-settings">
          <div class="export-wrapper">
            <ExportButton
              :disabled="store.loadingExcel"
              icon-name="file-spreadsheet"
              @click="store.downloadExcel()"
            >
              <span v-if="store.loadingExcel">(En cours)</span>
              <span v-else>Exporter en .xlsx</span>
            </ExportButton>
          </div>

          <!-- Items per Page -->
          <SelectItemsLimit
            :items-limit="selectedFilters.size"
            @change-limit="changeLimit"
          >
            Amendements
          </SelectItemsLimit>
          <SortBy
            :items="sortersNames"
            :selected-sort-item="currentSortLabel"
            @change-sort="handleSortChange"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import FiltersMenu from "@/components/reusable/organisms/FiltersMenu.vue";
import SearchAsYouTypeFilter from "@/components/reusable/molecules/SearchAsYouTypeFilter.vue";
import SelectMultipleCounter from "@/components/reusable/molecules/SelectMultipleCounter.vue";
import TotalMatched from "@/components/reusable/atoms/TotalMatched.vue";
import SelectItemsLimit from "@/components/reusable/atoms/SelectItemsLimit.vue";
import SortBy from "@/components/reusable/atoms/SortBy.vue";
import { useAmendmentsStore } from "@/stores/amendments";
import ExportButton from "@/components/reusable/atoms/ExportButton.vue";

const store = useAmendmentsStore();
const { selectedFilters } = storeToRefs(store);

// All filters in wanted display order with their labels
const filtersNames = {
  action: "Type d'action",
  status: "Statut",
  group: "Groupe",
  article: "Article",
};

// All sorters in wanted display order with their labels
const sortersNames = {
  created_at: "Date",
  nb_cosignataires: "Nombre de cosignataires",
};

const activeFilters = computed(() => {
  let arr = [];
  // Put all selected options from all filters with said filter name in an array for the SelectedFilters component
  Object.keys(filtersNames).forEach((key) => {
    selectedFilters.value[key]?.forEach((filter) => {
      arr = [...arr, { filter: key, item: filter }];
    });
  });

  selectedFilters.value.author?.forEach((filter) => {
    arr = [
      ...arr,
      {
        filter: "author",
        item: filter,
      },
    ];
  });

  return arr;
});

const currentSortField = ref(
  Object.keys(selectedFilters.value.sort)[0] ?? "created_at"
);

const currentSortLabel = computed(
  () => sortersNames[currentSortField.value] || "Sélectionner"
);

const handleSortChange = (selectedItem) => {
  const selectedSortField = Object.keys(sortersNames).find(
    (key) => sortersNames[key] === selectedItem
  );
  if (selectedSortField) {
    if (currentSortField.value !== selectedSortField) {
      currentSortField.value = selectedSortField;
      selectedFilters.value.sort = { [currentSortField.value]: "desc" };
      fetchAmendmentsList();
    }
  }
};

const changeLimit = (size) => {
  selectedFilters.value.size = size;
  selectedFilters.value.from = 0;
  fetchAmendmentsList();
};

const resetAllFilters = () => {
  store.resetFilters();
  fetchAmendmentsList();
};

const addFilterItem = (event) => {
  const targetFilters = selectedFilters.value[event.filter] || [];

  selectedFilters.value = {
    ...selectedFilters.value,
    [event.filter]: [...targetFilters, event.item],
  };
  fetchAmendmentsList();
};

const removeFilterItem = (event) => {
  let targetFilters = selectedFilters.value[event.filter] || [];

  targetFilters = targetFilters.filter((el) => el !== event.item);

  if (targetFilters.length === 0) {
    delete selectedFilters.value[event.filter];
  } else {
    selectedFilters.value = {
      ...selectedFilters.value,
      [event.filter]: targetFilters,
    };
  }
  fetchAmendmentsList();
};

const fetchSearchAuthors = (nameSearch) => {
  if (nameSearch.length > 0) {
    store.getSearchAuthorsFilters(nameSearch);
  } else {
    store.authors = [];
  }
};

const resetAuthorFilter = () => {
  store.authors = [];
  delete selectedFilters.value.author;
  fetchAmendmentsList();
};

const resetFilter = (event) => {
  delete selectedFilters.value[event];
  fetchAmendmentsList();
};

const fetchAmendmentsList = async () => {
  selectedFilters.value.from = 0;
  await store.fetchAmendmentsList();
  await store.fetchAmendmentsStats();
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/helpers/variables.scss";

.wrapper-filters {
  margin-top: 15px;
  margin-left: 15px;

  :deep(.filters) {
    border-radius: 21px;
  }
}
</style>
