<template>
  <RouterLink :to="routingUrl">
    <div class="card card-hover">
      <div class="header">
        {{ getDayTimeFormat(report.date_depot || report.created_at) }}
      </div>
      <ReportCardContent :report="report" />
    </div>
  </RouterLink>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { getDayTimeFormat } from "@/utils/formatDate";
import ReportCardContent from "./ReportCardContent.vue";

const route = useRoute();

const props = defineProps({
  report: { type: Object, required: true },
});

const routingUrl = computed(() => {
  let baseRoute = "";

  if (route.fullPath.includes("lobbies")) {
    baseRoute = "/lobbies";
    if (route.fullPath.includes("follawed")) {
      baseRoute += "/follawed";
    } else {
      baseRoute += `/${route.params._id}`;
    }
  }

  if (props.report.data_type === "rapport") {
    baseRoute += "/reports";
  } else {
    baseRoute += "/comptes-rendus";
  }

  return `${baseRoute}/${encodeURIComponent(props.report.ref)}`;
});
</script>

<style scoped lang="scss">
.card {
  height: 100%;
  color: var(--clr-n8);
}
</style>
