<template>
  <!-- petitions details -->
  <div class="card">
    <div class="header">
      <p>
        Date de création :
        <DataOrMissingData
          :data="
            store.currentPetition.published_at
              ? getDateShortFormat(store.currentPetition.published_at)
              : null
          "
        />
      </p>
      <p>
        Date d'échéance :
        <DataOrMissingData
          :data="
            store.currentPetition.deadline
              ? getDateShortFormat(store.currentPetition.deadline)
              : null
          "
        />
      </p>
      <SourceLink :href="store.currentPetition.url">{{
        store.currentPetition.origin
      }}</SourceLink>
    </div>

    <div class="body">
      <div class="description-content">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h5 v-html="store.currentPetition.title"></h5>
        <p>{{ store.currentPetition.commission }}</p>
        <p class="author">
          <i class="fa-solid fa-user-pen"></i>
          {{ store.currentPetition.author }}
        </p>
      </div>
      <FollawButton
        :element-id="store.currentPetition._id"
        :feature="FOLLAWED_FEATURES.PETITIONS"
      />
    </div>
  </div>

  <!-- Courbe de signatures -->
  <div class="card">
    <div class="header graph">
      <h6>Courbe temporelle des signatures</h6>
      <DownloadButton @click="downloadGraphExcel()" />
    </div>
    <SignaturesChart
      :signatures-history="store.currentPetition.signatures_history"
    />
  </div>

  <div class="datas-wrapper">
    <div class="left-column">
      <!-- Nombre de signatures -->
      <div class="card">
        <div class="nbre-signatures">
          <div class="row">
            <div>Nombre de signatures</div>
            <div>Dernières 24h</div>
          </div>
          <div class="row">
            <p>
              <b
                >{{ formatNumbers(store.currentPetition.signatures_number) }} /
                {{ formatNumbers(store.currentPetition.signatures_target) }}
              </b>
            </p>
            <p>
              <template v-if="store.currentPetition.last_24h > 0"> + </template>
              {{ formatNumbers(store.currentPetition.last_24h) }}
            </p>
          </div>
          <div class="row">
            <PetitionProgressBar :progression="getSignaturePercent" />
          </div>
        </div>
        <!-- Progression graph -->
      </div>
      <!-- Partages sur Twitter -->
      <div class="card tweets-container">
        <div class="tweets-container__title">
          <i
            class="fa-brands fa-lg fa-square-x-twitter"
            :style="'color: var(--clr-twitter)'"
          />
          <h6>Partages sur X</h6>
        </div>

        <p
          v-if="!store.currentPetition.x_authors?.length"
          class="twitter-datas-missing"
        >
          <i class="fa-solid fa-ban" /> Données absentes
        </p>
        <ul v-else class="tweets">
          <li v-for="tweet in store.currentPetition.x_authors" :key="tweet._id">
            <PetitionTweetCard :tweet="tweet" />
          </li>
        </ul>
      </div>
    </div>
    <!-- Details -->
    <div ref="petitionText" class="card typo-body-regular">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="store.currentPetition.text"></div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import xlsx from "json-as-xlsx";
import DataOrMissingData from "@/components/petitions/atoms/DataOrMissingData.vue";
import SignaturesChart from "@/components/petitions/atoms/SignaturesChart.vue";
import PetitionProgressBar from "@/components/petitions/atoms/PetitionProgressBar.vue";
import SourceLink from "@/components/general/atoms/SourceLink.vue";
import { usePetitionsStore } from "@/stores/petitions";
import formatNumbers from "@/utils/formatNumbers";
import { getDateShortFormat } from "@/utils/formatDate";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";
import DownloadButton from "@/components/reusable/atoms/DownloadButton.vue";
import PetitionTweetCard from "../molecules/PetitionTweetCard.vue";

const store = usePetitionsStore();

const getSignaturePercent = computed(() => {
  return Math.round(
    (Number(store.currentPetition.signatures_number) /
      Number(store.currentPetition.signatures_target)) *
      100
  );
});

function downloadGraphExcel() {
  const dataAsJson = [
    {
      sheet: "Pétition",
      columns: [
        { label: "Date", value: "date" },
        { label: "Signatures", value: "signatures_number" },
      ],
      content: store.currentPetition.signatures_history,
    },
  ];

  xlsx(dataAsJson, {
    fileName: `petition_${store.currentPetition._id}_signatures`,
  });
}
</script>

<style scoped lang="scss">
.card {
  color: var(--clr-n8);

  h4,
  p {
    margin: 0;
  }
}

.header {
  padding-bottom: 10px;
  margin-bottom: 10px;

  &.graph {
    border: none;
  }
}

.body {
  display: grid;
  grid-template-columns: 1fr 25px;
  gap: 15px;

  .description-content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .author {
      font-weight: 700;
    }
  }
}

.datas-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10px;
  // Keep important 1/3 ratio for lager screens
  @media (min-width: 1400px) {
    grid-template-columns: 1fr 2.5fr;
  }

  .left-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.nbre-signatures {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .row {
    display: flex;
    justify-content: space-between;
  }
}

.tweets-container {
  height: 100%;

  &__title {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .twitter-datas-missing {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 16px;
    margin-top: 32px;
    gap: 5px;
  }

  .tweets {
    overflow: auto;
    max-height: calc(100% - 40px);

    // ul
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: var(--clr-p2);
      margin-bottom: 5px;
      border-radius: 8px;
    }
  }
}
</style>
