<template>
  <div class="title-follaw-wrapper">
    <div class="title">
      <div v-if="!isHomepage" class="icon">
        <i class="fa-kit fa-xl fa-am"></i>
      </div>
      <div
        class="legi-title"
        @mouseenter="showDocLink = true"
        @mouseleave="showDocLink = false"
      >
        {{ amendment.legi_title }}
        <Transition name="fade">
          <div v-if="showDocLink" class="doc-link-hitbox">
            <div class="doc-link">
              <RouterLink
                :to="`/legislatives/${amendment.doc_ref}/expose-motifs`"
                ><i class="fa-kit fa-dossierlegi"></i
                ><span>Voir le dossier législatif</span></RouterLink
              >
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>

  <div class="statuses-wrapper">
    <div class="status">
      <AmendmentStatusTag :status="amendment.status" />
    </div>
    <div v-if="shouldDisplayLongStatus" class="status-long">
      <GenericTag background-color="#E7ECF4">{{
        amendment.status_long
      }}</GenericTag>
    </div>
  </div>

  <div class="author-and-infos-wrapper">
    <div v-if="amendment.author" class="author">
      <CardProfilePicture
        :picture-url="
          amendment.author?.photo || '/src/assets/img/legislation/gouv_fr.png'
        "
        class="picture"
        :size="48"
      />
      <div v-if="amendment.author" class="author-infos">
        <span class="author-name">{{ amendment.author.name }}</span>
        <GroupTag
          v-if="amendment.author.group"
          :group-color="amendment.author.group.color"
        >
          {{ amendment.author.group.acronym }}
        </GroupTag>
        <div v-if="amendment.nb_cosignataires">
          + {{ amendment.nb_cosignataires }} cosignataire{{
            amendment.nb_cosignataires > 1 ? "s" : ""
          }}
        </div>
      </div>
    </div>
    <ul class="infos">
      <li class="amendment-number">Amendment n° {{ amendment.number }}</li>
      <li>{{ amendment.title }}</li>
      <li>
        <i class="fa-solid fa-location-dot"></i>
        {{ getInstitutionsNames(amendment.institution) }}
        ({{ amendment.examined_by }})
      </li>
    </ul>
  </div>

  <div
    v-if="amendment.matched_words && amendment.matched_words.length > 0"
    class="content"
  >
    <span class="matched-title"
      >Mot{{ amendment.matched_words.length > 1 ? "s" : "" }} matché{{
        amendment.matched_words.length > 1 ? "s" : ""
      }}
      :
    </span>
    <!-- eslint-disable-next-line vue/no-v-html-->
    <span class="matched-words" v-html="listOfWords"></span>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import AmendmentStatusTag from "@/components/amendments/atoms/AmendmentStatusTag.vue";
import CardProfilePicture from "@/components/reusable/atoms/CardProfilePicture.vue";
import GroupTag from "@/components/reusable/atoms/GroupTag.vue";
import GenericTag from "@/components/reusable/atoms/GenericTag.vue";
import { getInstitutionsNames } from "@/utils/institutions.utils";

const props = defineProps({
  amendment: {
    type: Object,
    required: true,
  },
  isHomepage: {
    type: Boolean,
    default: false,
  },
});
const listOfWords = computed(() =>
  props.amendment.matched_words?.filter((_, idx) => idx < 10).join(", ")
);

const shouldDisplayLongStatus = computed(
  () =>
    props.amendment.status_long &&
    props.amendment.status_long !== props.amendment.status &&
    props.amendment.institution !== "SN"
);

const showDocLink = ref(false);
</script>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}

.matched-words {
  font-weight: 300;
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.title-follaw-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  .title {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border-radius: 50%;
      max-width: 32px;
      min-width: 32px;
      height: 32px;
      color: white;
      background-color: var(--clr-service-amendment);
    }

    .legi-title {
      position: relative;
      flex-grow: 1;
      font-weight: 600;

      &:hover {
        color: var(--clr-s7);
      }

      .doc-link-hitbox {
        position: absolute;
        z-index: 2;
        left: 75px;
        bottom: -33px;
        padding-top: 10px;

        .doc-link {
          position: relative;
          box-shadow: 0px 0px 7px 4px #00000014;
          border-radius: 4px;
          padding: 5px;
          color: var(--clr-link-default);
          background-color: white;
          font-weight: 400;

          &::after {
            content: "";
            position: absolute;
            border-width: 5px;
            border-style: solid;
            bottom: 100%;
            left: 8px;
            border-color: transparent transparent white transparent;
          }

          &:hover {
            text-decoration: underline;
          }

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.statuses-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  font-size: 0.938em;
  font-weight: 700;

  .tag {
    text-transform: capitalize;
  }
}

.author-and-infos-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;

  .author {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 0.938em;
    border-right: 1px solid var(--clr-s3);

    .author-infos {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .author-name {
        text-transform: capitalize;
      }
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    font-weight: 300;
    margin: 0;
    line-height: 20.46px;
    padding-left: 10px;
    color: var(--clr-s7);
    list-style-type: none;
  }

  .amendment-number {
    font-weight: 500;
  }
}

.content {
  .matched-title {
    font-weight: 500;
    color: var(--clr-s7);
  }
}
</style>
