import { isAxiosError } from "axios";
import { useToast } from "vue-toastification";

import { useAuthStore } from "@/stores/auth";

const ERROR_REGEX = {
  MAX_QUERY_SIZE: /ElasticSearch max query size reached/i,
  REVOKED_TOKEN: /revoked.*token/i,
  PERMISSION_LOW: /permission.*low/i,
  EXISTING_EMAIL: /Email already used./i,
};
const toast = useToast();

// This is the default function to handle network errors
// When a function has an network error that it does not know how to handle,
// it can pass it to this function to handle it

export function handleNetworkError(error) {
  if (!isAxiosError(error)) {
    throw error || new Error("Erreur inconnue");
  }

  const status = error.response?.status;
  const msg =
    (typeof error.response?.dataMsg === "string" && error.response?.dataMsg) ||
    (typeof error.message === "string" && error.message) ||
    "Impossible de récupérer le message d'erreur.";

  if (status === 401 && msg.match(ERROR_REGEX.REVOKED_TOKEN)) {
    // Unauthorized - clear session and go back to login page
    // Only display an error message if the user currently has an invalid token
    if (localStorage?.getItem(window._fsv_config.ACCESS_TOKEN_KEY)) {
      localStorage?.removeItem(window._fsv_config.ACCESS_TOKEN_KEY);
    }

    const authStore = useAuthStore();
    authStore.hasValidToken = false;
    toast.error("Votre session a expirée, veuillez vous reconnecter.");
  } else if (status === 400 && msg.match(ERROR_REGEX.EXISTING_EMAIL)) {
    toast.error(
      "Cette adresse email est déjà utilisée. Veuillez en choisir une autre."
    );
  } else if (msg.match(ERROR_REGEX.PERMISSION_LOW)) {
    toast.error("Votre abonnement ne vous donne pas accès à cette section.");
  } else if (msg.match(ERROR_REGEX.MAX_QUERY_SIZE)) {
    toast.error(
      "Limite de mots-clés pour une requête atteinte. Veuillez décocher des filtres."
    );
  } else if (status === 500) {
    toast.error(
      "Une erreur serveur est survenue. Notre équipe technique a été prévenue."
    );
    throw error;
  } else {
    toast.error(
      msg || "Erreur inconnue. Notre équipe technique a été prévenue."
    );
    throw error;
  }
}
