import { defineStore } from "pinia";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { httpRequest } from "@/utils/request.utils";
import { handleNetworkError } from "@/utils/handleNetworkError";

const toast = useToast();
export const useUserSettingsStore = defineStore("userSettingsStore", () => {
  const user = ref(null);

  const loadingFetch = ref(false);
  const loadingUpdate = ref(false);

  const hasPermission = (permissionLevelToTest) => {
    // Hide permission logic in production, where we want all users to have all access until the release of Freemium
    if (import.meta.env.VITE_ENV === "production") {
      return true;
    }

    if (!user.value || !user.value.permission_level) {
      return false;
    }

    if (permissionLevelToTest === "FREE") {
      return true;
    }

    if (permissionLevelToTest === "BASIC") {
      return (
        user.value.permission_level === "BASIC" ||
        user.value.permission_level === "TEAMS_BASIC" ||
        user.value.permission_level === "SOCIETAL" ||
        user.value.permission_level === "TEAMS_SOCIETAL" ||
        user.value.permission_level === "TRIAL"
      );
    }

    if (permissionLevelToTest === "SOCIETAL") {
      return (
        user.value.permission_level === "SOCIETAL" ||
        user.value.permission_level === "TEAMS_SOCIETAL" ||
        user.value.permission_level === "TRIAL"
      );
    }

    return true;
  };

  async function getUserSettings() {
    loadingFetch.value = true;

    try {
      const response = await httpRequest("get", "/users/user-profile");
      if (response.data) {
        user.value = response.data;
      }
    } catch (err) {
      handleNetworkError(err);
    } finally {
      loadingFetch.value = false;
    }
  }

  async function updateUserSettings(payload) {
    loadingUpdate.value = true;

    if (!payload) {
      return;
    }

    if (payload.email) {
      // prevent users from changing their email
      delete payload.email;
    }

    try {
      const response = await httpRequest("patch", "/users", payload);
      if (response.status === 200) {
        user.value = {
          ...user.value,
          ...payload,
          ...response.data,
        };
      }

      toast.success("Vos informations ont été mises à jour");
    } catch (err) {
      handleNetworkError(err);
    }
    loadingUpdate.value = false;
  }

  async function redirectToStripePricePage(planName) {
    try {
      let planUrl = "";
      if (planName === "FREE") {
        planUrl = user.value.stripe_portal_url;
      } else {
        const response = await httpRequest("post", "/create-checkout", {
          plan_name: planName,
        });
        planUrl = response.data.redirect_url;
      }

      window.location.href = planUrl;
    } catch (err) {
      if (
        err.response?.status === 403 &&
        err.response.dataMsg.match(/SV employees.*not allowed/i)
      ) {
        toast.error(err.response.dataMsg);
      } else {
        handleNetworkError(err);
      }
    }
  }

  return {
    getUserSettings,
    hasPermission,
    loadingFetch,
    loadingUpdate,
    redirectToStripePricePage,
    updateUserSettings,
    user,
  };
});
