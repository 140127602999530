<template>
  <RouterLink :to="linkUrl">
    <div class="card card-hover">
      <section class="header title-small-regular">
        <div>Déposé le : {{ getDateShortFormat(legiWork.depot_initial) }}</div>
        <div>
          Dernière mise à jour : {{ getDateShortFormat(lastUpdateDate) }}
        </div>
        <div>{{ legiWork.legislature }}e législature</div>
        <FollawButton
          :id="`legiwork-follaw-btn-${props.idx}`"
          :element-id="props.legiWork._id"
          :feature="FOLLAWED_FEATURES.LEGIDOCS"
        />
      </section>
      <LegiCardContent :legi-work="legiWork" />
    </div>
  </RouterLink>
</template>

<script setup>
import { computed } from "vue";
import FollawButton from "@/components/reusable/atoms/FollawButton.vue";
import { FOLLAWED_FEATURES } from "@/stores/user";
import { useUserStore } from "@/stores/user";
import { getDateShortFormat } from "@/utils/formatDate";
import LegiCardContent from "./LegiCardContent.vue";

const userStore = useUserStore();

const props = defineProps({
  legiWork: {
    type: Object,
    required: true,
  },
  idx: {
    type: Number,
    required: false,
    default: 0,
  },
});

const isFollawed = computed(() => {
  if (!userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS]) {
    return false;
  }
  return userStore.follawed[FOLLAWED_FEATURES.LEGIDOCS]?.includes(
    props.legiWork._id
  );
});

const linkUrl = computed(() =>
  isFollawed.value
    ? `/legislatives/${props.legiWork.ref}/timeline`
    : `/legislatives/${props.legiWork.ref}/expose-motifs`
);

const lastUpdateDate = computed(() => props.legiWork.latest_update);
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
}

.header {
  display: flex;
  gap: 4px;
  color: var(--clr-s8);
  border-bottom: 2px solid var(--color-laws);
}
</style>
