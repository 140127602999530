<template>
  <div v-if="store.isActivitiesLoading">
    <LoaderFullPage />
  </div>

  <template v-else-if="store.prmActivities?.length">
    <div class="activities-wrapper" :class="{ zoomed: !isFollawed }">
      <template v-for="item in store.prmActivities" :key="item._id">
        <PublicationCard
          v-if="PLATFORM_TYPES.includes(item.type)"
          :post="{ ...item, data_type: item.type }"
          :tooltip-activable="false"
        />
        <QuestionCard
          v-if="item.type === 'question'"
          :question="item"
          :to="`/prm-national/${route.params._id || 'follawed'}/question/${item._id}`"
        />
        <LegiWorkCard v-if="item.type === 'legi-doc'" :legi-work="item" />
        <AmendmentCard
          v-if="item.type === 'amendment'"
          :amendment="item"
          :to="`/prm-national/${route.params._id || 'follawed'}/amendment/${item._id}`"
        />
        <ReportCard v-if="item.type === 'compte-rendu'" :report="item" />
      </template>
    </div>
    <div class="pagination-wrapper">
      <PaginationElement
        v-if="totalCount"
        :key="`${totalCount}${store.selectedPrmActivitiesParams.from}${store.selectedPrmActivitiesParams.size}`"
        :total-items="totalCount"
        :current-page="store.selectedPrmActivitiesParams.from"
        :items-limit="store.selectedPrmActivitiesParams.size"
        @change-current-page="changeCurrentPage"
      />
    </div>
  </template>

  <div v-else>
    <NoData />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import QuestionCard from "@/components/questions/atoms/QuestionCard.vue";
import AmendmentCard from "@/components/amendments/molecules/AmendmentCard.vue";
import LegiWorkCard from "@/components/legi-work/molecules/LegiWorkCard.vue";
import ReportCard from "@/components/legi-reports/molecules/ReportCard.vue";

import { usePrmStore } from "@/stores/prm";
import { PLATFORM_TYPES } from "@/utils/social360-platforms.js";
import PublicationCard from "@/components/social-media/posts/molecules/PublicationCard.vue";

const route = useRoute();
const store = usePrmStore();

const isFollawed = ref(route.fullPath.includes("follawed") ? true : false);

const totalCount = computed(() => {
  let count = 0;
  Object.values(store.indexCount).forEach((c) => {
    count += c;
  });
  return count;
});

const changeCurrentPage = (from) => {
  store.selectedPrmActivitiesParams.from = from;
  isFollawed.value
    ? store.fetchFollawedPrmActivities()
    : store.fetchOnePersonnalityActivities(route.params._id);
};
</script>

<style lang="scss" scoped>
.activities-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  &.zoomed {
    display: flex;
    flex-direction: column;
  }
}
.pagination-wrapper {
  grid-column: span 2;
}
</style>
