<template>
  <div
    class="card"
    :class="[isSelected ? 'selected' : '']"
    @mouseover="showCheckbox = true"
    @mouseleave="showCheckbox = false"
  >
    <div class="card-head header">
      {{ dayjs(event.start).format("dddd DD/MM/YYYY HH:mm") }}-{{
        dayjs(event.start).format("YYYYMMDD") ===
        dayjs(event.end).format("YYYYMMDD")
          ? dayjs(event.end).format("HH:mm")
          : dayjs(event.end).format("dddd DD/MM/YYYY HH:mm")
      }}
      <div v-if="!noSelect" class="checkbox-and-close">
        <CheckBox
          :class="{
            'checkbox-hidden': !(showCheckbox || isSelected || $props.isDialog),
          }"
          :checked="isSelected"
          @change.stop="
            () =>
              isSelected
                ? $emit('unselectEvent', event)
                : $emit('selectEvent', event)
          "
        />
        <!-- Close button if the Dialog box is on -->
        <CloseButton v-if="isDialog" @click="$emit('closeDialog')" />
      </div>
    </div>
    <EventCardContent :event="event" />
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import { ref } from "vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import CloseButton from "@/components/reusable/atoms/CloseButton.vue";
import EventCardContent from "./EventCardContent.vue";

defineProps({
  event: {
    type: Object,
    required: true,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
  isSelected: {
    type: Boolean,
    required: true,
  },
  noSelect: {
    type: Boolean,
    default: false,
  },
});

const showCheckbox = ref(false);

defineEmits(["selectEvent", "unselectEvent", "closeDialog"]);
</script>

<style lang="scss" scoped>
.card {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;

  &.selected {
    border-color: var(--clr-p7);
  }

  .card-head {
    text-transform: capitalize;
    margin-bottom: 8px;

    .checkbox-hidden {
      opacity: 0;
    }
    .date {
      padding-bottom: 1px;
      font-size: 0.938em;
      font-weight: 300;
      text-transform: capitalize;
    }

    input[type="checkbox"] {
      display: none;
    }
  }
}

.checkbox-and-close {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
</style>
