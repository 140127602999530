<template>
  <div v-if="store.isNewsLoading">
    <LoaderFullPage />
  </div>
  <template
    v-else-if="store.prmNewsPublications?.length || store.prmNewsLinks?.length"
  >
    <div class="news-wrapper" :class="{ zoomed: !isFollawed }">
      <div v-if="store.prmNewsPublications.length" class="column">
        <h4>Publications</h4>
        <XCard
          v-for="item in store.prmNewsPublications"
          :key="item._id || item.id_twitter"
          :post="item"
        />
      </div>
      <div v-if="store.prmNewsLinks.length" class="column">
        <h4>Articles</h4>
        <XArticleCard
          v-for="item in store.prmNewsLinks"
          :key="item._id || item.id_twitter"
          :article="item"
        />
      </div>
    </div>
    <div class="pagination-wrapper">
      <PaginationElement
        :key="`${maxCount}${store.selectedPrmNewsParams.from}${store.selectedPrmNewsParams.size}`"
        :total-items="maxCount"
        :current-page="store.selectedPrmNewsParams.from"
        :items-limit="store.selectedPrmNewsParams.size"
        @change-current-page="changeCurrentPage"
      />
    </div>
  </template>

  <div v-else>
    <NoData />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import XCard from "@/components/social-media/posts/molecules/XCard.vue";
import XArticleCard from "@/components/social-media/molecules/XArticleCard.vue";
import { usePrmStore } from "@/stores/prm";

const route = useRoute();
const store = usePrmStore();

const isFollawed = ref(route.fullPath.includes("follawed") ? true : false);

const maxCount = computed(() => Math.max(store.tweetCount, store.linkCount));

const changeCurrentPage = (from) => {
  store.selectedPrmNewsParams.from = from;
  isFollawed.value
    ? store.fetchFollawedPrmNews()
    : store.fetchOnePersonnalityNews(route.params._id);
};
</script>

<style lang="scss" scoped>
.news-wrapper {
  display: flex;
  gap: 10px;

  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
      margin: 0;
    }
  }
}

.pagination-wrapper {
  grid-column: span 2;
}
</style>
