<template>
  <div class="mentions-zoom">
    <div class="mentions-zoom__list__title">
      <p class="title-large-semibold">Mentions issues du réseau social</p>
      <i
        class="fa-brands fa-lg fa-square-x-twitter"
        :style="'color: var(--clr-twitter)'"
      />
    </div>

    <div
      v-if="storeStakeholder.isRelatedMentionsLoading"
      class="mentions-zoom__loader"
    >
      <LoaderCircle />
    </div>

    <template v-else>
      <XCard
        v-for="post in storeStakeholder.zoomMentions"
        :key="post._id"
        :post="post"
      />
      <PaginationElement
        v-if="storeStakeholder.zoomMentionsCount"
        :total-items="storeStakeholder.zoomMentionsCount"
        :current-page="storeStakeholder.zoomMentionsFilters.from"
        :items-limit="storeStakeholder.zoomMentionsFilters.size"
        @change-current-page="changeCurrentPage"
      />
    </template>
    <div
      v-if="
        !storeStakeholder.zoomMentionsCount &&
        !storeStakeholder.isRelatedMentionsLoading
      "
      class="card"
    >
      <EmptySection
        message="Cette partie prenante n’a pas de mentions correspondant à votre requête."
      />
    </div>
  </div>
</template>

<script setup>
import XCard from "@/components/social-media/posts/molecules/XCard.vue";

import { useStakeholdersStore } from "@/stores/social-media/stakeholders";
import { useUserSettingsStore } from "@/stores/user-settings";
import EmptySection from "@/components/reusable/molecules/EmptySection.vue";
import LoaderCircle from "@/components/reusable/atoms/LoaderCircle.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";

const storeStakeholder = useStakeholdersStore();
const userSettingsStore = useUserSettingsStore();

const changeCurrentPage = (from) => {
  storeStakeholder.zoomMentionsFilters.from = from;
  storeStakeholder.getMentionsOfPublications(
    storeStakeholder.authorZoom.x_information?.username
  );
};

if (userSettingsStore.hasPermission("SOCIETAL")) {
  storeStakeholder.zoomMentionsFilters.from = 0;
  storeStakeholder.getMentionsOfPublications(
    storeStakeholder.authorZoom.x_information?.username
  );
}
</script>

<style scoped lang="scss">
.mentions-zoom {
  display: flex;
  gap: 8px;
  flex-direction: column;

  &__list__title {
    display: flex;
    align-items: center;
    gap: 4px;

    & > .title-large-semibold {
      margin: 0;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>
