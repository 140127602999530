<template>
  <template v-if="twitterArticlesStore.items.length">
    <div class="datas-wrapper">
      <div id="articles-data" class="articles-section">
        <div v-if="twitterArticlesStore.items" class="articles-list">
          <template
            v-if="twitterArticlesStore.selectedFilters.from + 1 <= MAX_PAGE"
          >
            <XArticleCard
              v-for="article in twitterArticlesStore.items"
              :key="article._id"
              :article="article"
              :is-zoomed="article._id === zoomedArticleId"
              clickable-metrics
              @activate-zoom="activateZoom"
              @close-zoom="zoomedArticleId = null"
            />
          </template>

          <template v-else>
            <div class="card">
              <p>
                Il n'est pas possible d'afficher les pages au-delà de
                {{ MAX_PAGE }} pour l'instant.
              </p>

              <p>
                <button class="btn-primary" @click="() => changeCurrentPage(0)">
                  Revenir à la page 1
                </button>
              </p>
            </div>
          </template>
        </div>

        <PaginationElement
          v-if="twitterArticlesStore.item_count"
          :key="`${twitterArticlesStore.item_count}${twitterArticlesStore.selectedFilters.from}${twitterArticlesStore.selectedFilters.size}`"
          :total-items="twitterArticlesStore.item_count"
          :current-page="twitterArticlesStore.selectedFilters.from"
          :items-limit="twitterArticlesStore.selectedFilters.size"
          @change-current-page="changeCurrentPage"
        />
      </div>
      <div v-show="isZoomed" class="zoomed-article-tweets">
        <h3 class="sub-title">
          Article partagé par :
          <button @click="zoomedArticleId = null">
            <i class="fa-solid fa-x"></i>
          </button>
        </h3>
        <div v-if="twitterArticlesStore.isPublicationsLoading" class="loading">
          <LoaderFullPage />
        </div>
        <ul v-else class="zoomed-article-tweets-list">
          <li
            v-for="tweet in twitterArticlesStore.relatedTweets"
            :key="tweet._id"
          >
            <XCard :post="tweet" />
          </li>
        </ul>
      </div>
    </div>
  </template>
  <template
    v-if="
      !twitterArticlesStore.loading && twitterArticlesStore.item_count === 0
    "
  >
    <div>
      <NoData />
    </div>
  </template>
</template>

<script setup>
import { ref, computed } from "vue";

import XArticleCard from "@/components/social-media/molecules/XArticleCard.vue";
import XCard from "@/components/social-media/posts/molecules/XCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";
import { useTwitterArticlesStore } from "@/stores/social-media/articles";

const twitterArticlesStore = useTwitterArticlesStore();

const zoomedArticleId = ref(null);
const isZoomed = computed(() => !!zoomedArticleId.value);

// Display a special message to users above a certain page
// This is because of a back-end limitation
const MAX_PAGE = 100;

const changeCurrentPage = async (from) => {
  twitterArticlesStore.selectedFilters.from = from;
  if (from + 1 <= MAX_PAGE) {
    await twitterArticlesStore.fetchTwitterArticlesList();
  }
};

function activateZoom(articleId) {
  zoomedArticleId.value = articleId;
  twitterArticlesStore.fetchRelatedTweetsOfArticle(articleId);
}
</script>

<style scoped lang="scss">
.datas-wrapper {
  display: flex;
  // hardcoded value corresponding to the header elements above the list
  height: calc(100vh - 187px);
}

.articles-section {
  flex-grow: 1;
  container-type: inline-size;
  height: 100%;
  overflow-y: auto;
}

.articles-list {
  display: grid;
  gap: 8px;

  :deep(.card) {
    height: 100%;
  }

  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.zoomed-article-tweets {
  width: 570px;
  background-color: var(--clr-service-twitter);
  color: white;

  height: 100%;
  overflow-y: auto;
  border-radius: 4px;

  padding: 4px;
  margin-left: 8px;

  .loading {
    height: 50vh;
  }

  .sub-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 4px 0 16px 16px;

    button {
      font-size: 0.8em;
      width: 25px;
      height: 25px;
      padding: 4px;
      border-radius: 50%;
      &:hover {
        background-color: white;
        color: var(--clr-service-twitter);
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      background-color: white;
      color: black;
      margin-bottom: 5px;
      border-radius: 8px;
    }
  }
}
</style>
