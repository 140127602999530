<template>
  <AbstractCard
    color="var(--clr-service-agenda)"
    icon="fa-solid fa-calendar-lines"
    follawed-name="follawed_events"
    :doc-id="document._id"
    :target-url="`/agendas`"
  >
    <template #header>
      <div class="date">
        {{ getDateTimeLongFormat(document.start) }}-{{
          formatDateToCompare(document.start) ===
          formatDateToCompare(document.end)
            ? getHourTimeFormat(document.end)
            : getDateTimeLongFormat(document.end)
        }}
      </div>
    </template>
    <template #content>
      <EventCardContent :event="document" />
    </template>
  </AbstractCard>
</template>

<script setup>
import AbstractCard from "../abstract/AbstractCard.vue";
import EventCardContent from "@/components/agenda/organisms/EventCardContent.vue";
import dayjs from "dayjs";
import { getDateTimeLongFormat, getHourTimeFormat } from "@/utils/formatDate";

defineProps({
  document: {
    type: Object,
    required: true,
  },
});

const formatDateToCompare = (date) => {
  return dayjs(date).format("YYYYMMDD");
};
</script>

<style lang="scss" scoped>
.date {
  text-transform: capitalize;
}
</style>
