<template>
  <div class="body">
    <div class="title-row">
      <InstitutionBadge v-if="!isHomepage" :institution="report.institution" />
      <div class="title title-medium-semibold">{{ report.title }}</div>
    </div>

    <div v-if="isHomepage">
      <span class="detail-title title-medium-regular">
        <i class="fa-solid fa-location-dot"></i> Institution :</span
      >
      {{ getInstitutionsNames(report.institution) }}
    </div>

    <div v-if="hasMatchedWords">
      <span class="detail-title title-medium-regular">Mots matchés :</span>
      {{ report.matched_words.join(", ") }}
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import InstitutionBadge from "@/components/legi-work/atoms/InstitutionBadge.vue";
import { getInstitutionsNames } from "@/utils/institutions.utils";

const props = defineProps({
  report: { type: Object, required: true },
  isHomepage: { type: Boolean, default: false },
});

const hasMatchedWords = computed(
  () => Object.keys(props.report.matched_words || {}).length > 0
);
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    .title::first-letter {
      text-transform: capitalize;
    }
  }

  .detail-title {
    color: var(--clr-s7);
  }
}
</style>
