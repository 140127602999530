<template>
  <template v-if="store.items.length">
    <!-- Cards -->
    <div class="cards-wrapper">
      <LobbyCard
        v-for="lobby in store.items"
        :key="lobby._id"
        :lobby="lobby"
      ></LobbyCard>
    </div>

    <!-- Pagination -->
    <PaginationElement
      v-if="store.itemCount"
      :key="`${store.itemCount}${store.selectedFilters.from}${store.selectedFilters.size}`"
      :total-items="store.itemCount"
      :current-page="store.selectedFilters.from"
      :items-limit="store.selectedFilters.size"
      @change-current-page="changeCurrentPage"
    />
  </template>
  <NoData v-else-if="store.hasActiveFilters" />
  <NoDataFollawed v-else-if="store.isFollawedActive" />
  <NoData v-else />
</template>

<script setup>
import LobbyCard from "../molecules/LobbyCard.vue";
import PaginationElement from "@/components/reusable/atoms/PaginationElement.vue";
import NoDataFollawed from "@/components/reusable/atoms/NoDataFollawed.vue";
import NoData from "@/components/reusable/atoms/NoData.vue";

import { useLobbiesStore } from "@/stores/lobbies";

const store = useLobbiesStore();

const changeCurrentPage = (from) => {
  store.selectedFilters.from = from;
  store.readLobbies();
};
</script>

<style lang="scss" scoped>
.cards-wrapper {
  container-type: normal;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 10px;
}
</style>
